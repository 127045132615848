import {
  Context,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from "react";
import { CloseIcon } from "../assets/icons";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../components/ui/dialog";
import { cls } from "../utils/helpers/cls";

export type ModalProps = {
  openModal: (props: OpenModalProps) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalProps | null>(null);

export const useModal = () => {
  const context = useContext<ModalProps>(
    ModalContext as unknown as Context<ModalProps>
  );

  if (!context) {
    throw new Error("Using useModal outside of ModalProvider");
  }

  return context;
};

type OpenModalProps =
  //    VariantProps<typeof buttonVariants> &
  {
    content: ReactNode;
    title?: string;
    description?: string;
    subTitle?: string;
    className?: string;
  };
export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [className, setClassName] = useState("");
  const [description, setDescription] = useState("");
  const [subTitle, setSubTitle] = useState("");
  // const [variant, setVariant] =
  //   useState<VariantProps<typeof buttonVariants>["variant"]>("primary");
  const [content, setContent] = useState<ReactNode | null>();

  const openModal = ({
    content,
    title,
    description,
    className,
    subTitle,
  }: //   variant,
  OpenModalProps) => {
    setOpen(true);
    setContent(content);
    setDescription(description || "");
    setTitle(title || "");
    setClassName(className || "");
    setSubTitle(subTitle || "");
    //   setVariant(variant);
  };
  const closeModal = () => {
    setOpen(false);
    setContent(null);
    setDescription("");
    setTitle("");
    setClassName("");
    setSubTitle("");
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Dialog open={open} onOpenChange={closeModal}>
        <DialogContent
          className={cls(
            "card-bg p-0 border-1 border-main gap-0 overflow-hidden",
            className
          )}
        >
          {!!title && (
            <div className="flex flex-col justify-center  w-full p-4 gap-y-2 pb-2 pt-5 relative">
              <DialogTitle className="flex-1 text-main">{title}</DialogTitle>
              <DialogTitle className="flex-1 text-sm text-muted font-normal">
                {subTitle}
              </DialogTitle>
            </div>
          )}
          <DialogClose>
            <CloseIcon
              className={cls(
                " bg-tw-black/10 dark:text-white max-h-7 max-w-7  h-7 w-7 absolute top-2 right-2 rounded-full text-white p-1"
              )}
            />
          </DialogClose>
          {content}
          {!!description && (
            <div className="p-4 flex flex-col gap-y-1">
              {!!description && (
                <DialogDescription className="text-muted">
                  {description}
                </DialogDescription>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
};
